<template>
  <div class="pay-monthly-rent">
    <h1 class="title">{{$t('Enter card to pay monthly rent')}}</h1>
    <div class="monthly-rent mt-4">
      <div class="monthly-rent-text">
        <p v-if="descriptionMessage" class="montserrat-medium rf-blue-color mt-3 mb-1">{{$t(this.descriptionMessage)}}</p>
        <p v-if="secondDescriptionMessage" class="montserrat-medium rf-blue-color mb-1">{{$t(this.secondDescriptionMessage)}}</p>
      </div>
    </div>
    <form @submit.prevent class="pay-monthly-rent-form" name="payment-form" id="payment-form" enctype="multipart/form-data">
      <b-row class="card-box mt-4">
        <b-col cols="auto">
          <FontAwesomeIcon color="#24425B" :icon="['fas', 'credit-card']" class="mb-2"/>
        </b-col>
        <b-col col>
          <div id="xpay-card"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2">
          <RFButton class="mt-4" :label="$t('PayMonthlyRentPayent')" background="#24425B" color="#fff" @on-click="payment" v-if="!loading"/>
          <RFLoader class="mt-4" :size="30" color="#24425B" v-if="loading"/>
        </b-col>
      </b-row>
      <div v-if="reservation" class="last-payment-div mt-3">
        <p><b>{{$t('AmountMonthlyRentPayment')}}:</b> &euro;{{reservation.monthlyAmount}}</p>
      </div>
    </form>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'

import { paymentService, userService } from '@/services'
import { mapState } from 'vuex'
import { getMonth } from '@/helpers/functions'

export default {
  name: "CreditCardMonthlyRentPayment",
  components: {
    RFButton,
    RFLoader,
  },
  props: {
    setMonthlyPaymentsToAutomatic:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      paymentConfig: null,
      loading: false,
      card: null,
      currentReservation: null,
      descriptionMessage: null,
      secondDescriptionMessage: null,
      payPressed: false,
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.profileStore.reservation,
      user: state => state.userStore.user
    }),
  },
  created() {
    const nexiPayScript = this.nexiPayScript = document.createElement('script')
    nexiPayScript.setAttribute('src', process.env.VUE_APP_PAYMENT_BASE_URL)
    document.head.appendChild(nexiPayScript)
  },
  async beforeMount() {

    this.descriptionMessage = 'Insert credit card to pay the current month payment'
    if(this.setMonthlyPaymentsToAutomatic){
      this.secondDescriptionMessage = '(this card will be used to pay the following payments automatically)'
    }

    if(this.reservation==null){
      const currentReservation = await userService.getReservationById(this.user.id,this.$route.params.id)
      this.$store.commit('profileStore/setReservation', currentReservation)
    }

    this.paymentConfig = await paymentService.getMonthlyRentPaymentConfig(this.reservation.id)

    window.addEventListener('load', () => this.initXPay(this.paymentConfig))
  },
  async mounted() {
    window.addEventListener('XPay_Ready', (event) => this.handlerXPayReady(event))
    window.addEventListener('XPay_Payment_Started', (event) => this.handlerXPayStarted(event))
    window.addEventListener('XPay_Card_Error', (event) => this.handlerXPayError(event))
    window.addEventListener('XPay_Nonce', async (event) => await this.handlerXPayNonce(event))
  },
  beforeDestroy() {
    this.nexiPayScript.parentNode.removeChild(this.nexiPayScript)
    this.$store.commit('profileStore/setReservation', null)
  },
  methods: {
    handlerXPayReady(event) {},
    handlerXPayStarted(event) {},
    handlerXPayError(event) {
      if(this.payPressed && event.detail.errorMessage!==undefined){
        this.payPressed = false
        this.$toasted.error(this.$i18n.t(event.detail.errorMessage))
      }
    },
    async handlerXPayNonce(event) {
      this.loading = true

      const { detail: response } = event
      const { dettaglioCarta, idOperazione, esito, xpayNonce, timeStamp, mac } = response

      if (esito === 'OK') {
        const form = {
          apiKey: this.paymentConfig.apiKey,
          codiceTransazione: this.paymentConfig.codiceTransazione,
          importo: this.paymentConfig.amount,
          divisa: this.paymentConfig.currency,
          xpayNonce,
          idOperazione,
          timeStamp,
          esito,
          mac,
          dettaglioCarta,
          numeroContratto: this.reservation.numeroContratto,
        }

        let confirm;

        //Make the corresponding API call depending on the case
        if(this.setMonthlyPaymentsToAutomatic){
          confirm = await paymentService.confirmMonthlyRentAutomaticPayment(this.reservation.id, form)
        }
        else{
          confirm = await paymentService.confirmMonthlyRentPayment(this.reservation.id, form)
        }


        if (confirm.esito === 'KO') {
          this.$toasted.error(this.$i18n.t(`Payment could not be made. Please try again`))
          window.setTimeout(() => window.location = this.$route.path, 1000)
          return false
        }

        this.$store.commit('profileStore/setReservation', null)

        const currentDate = new Date()
        const currentMonth = getMonth(currentDate.getMonth())

        this.$toasted.success(this.$i18n.t(`The payment for month currentMonth is already done`,{currentMonth: this.$i18n.t(currentMonth)}))
        this.$router.push({ name: 'your-roof' })
        this.loading = false
      } else {
        this.loading = false
      }
    },
    initXPay(paymentConfig) {

      XPay.init()

      this.setNexiConfig(paymentConfig)
      XPay.setInformazioniSicurezza({})

      const style = {
        common: {
          color: '#000',
          ':hover': {
            color: '#000'
          }
        },
        error: {
          color: '#FF5A5F',
        },
        correct: {
          color: '#5CB85C',
        }
      }

      const card = this.card = XPay.create(XPay.OPERATION_TYPES.CARD, style)
      card.mount('xpay-card')
    },
    setNexiConfig(paymentConfig){
      const { apiKey, environment, amount, codiceTransazione, currency, timeStamp, mac, language } = paymentConfig
      let url = `${process.env.VUE_APP_BASE_URL}/payments/monthlyrent/access/${this.reservation.id}`
      let urlBack = `${process.env.VUE_APP_BASE_URL}/payments/monthlyrent/confirm/${this.reservation.id}`

      //Modify the urls depending on the case

      //Set following payments to automatic
      if(this.setMonthlyPaymentsToAutomatic){
        urlBack = `${process.env.VUE_APP_BASE_URL}/payments/monthlyrentAutomatic/confirm/${this.reservation.id}`
      }

      let config = {
        baseConfig: {
          apiKey,
          enviroment: environment//=XPay.Environments.INTEG
        },
        paymentParams: {
          amount,
          transactionId: codiceTransazione,
          currency,
          timeStamp,
          mac,
          url,
          urlBack,
        },
        customParams: {},
        language,//=XPay.LANGUAGE.ITA,
      }
      //If is setting the following payments to automatic, it has to set the corresponding parameters to the request
      if(this.setMonthlyPaymentsToAutomatic){
        config.serviceType = 'paga_multi'
        config.requestType = 'PP'
      }

      XPay.setConfig(config)

    },
    payment() {
      this.payPressed = true
      XPay.createNonce('payment-form', this.card)
    }
  }
}
</script>

<style lang="scss" scoped>
.pay-monthly-rent {
  min-height: 80vh;
  margin-top: 30px;

  .title {
    @include font-style($montserrat, 'medium', $font-20);
    color: $dark-gray;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: .5px solid $dark-gray;
  }
  .pay-monthly-rent-form {
    .card-box {
      margin: 0 5px;
      padding-top: 10px;
      border: 1px solid $dark-blue;
    }
  }
  .last-payment-div {
    @include font-style($montserrat, 'medium', $font-16);
    color: $dark-blue;
  }
  .monthly-rent-text {
    @include font-style($montserrat-bold, 'bold', $font-14);
    color: var(--color);

    .mail {
      color: var(--color);
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .pay-monthly-rent {
    padding-left: 50px;
    margin-top: 0;
  }
}
</style>
